.header{
  position: fixed;
  z-index: 9000;
}

.h1-title{
  margin: 2.5% 2.5%;
  text-align: center;
  font-size: xx-large;
}
.div-space{
  height: 2.5%;
  margin-top: 100px;
}

.body{
  margin-top: 60px;
}

.top-body{
  width: 100%;
  height: auto;
  background: linear-gradient(#231d35 0%,#090a0e 100%)
}

.Life_h1_text{
    color: white;
    margin: auto 0; 
    text-align: center;
}

.Life_top_p_text{
    color: white;
    margin: auto 0; 
    text-align: center;
    font-size: large;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.lylcs_text{
    color: white;
    margin: auto 0; 
    text-align: center;
}

.Life_Talk_Blk{
    display: block;
    margin: auto;
}

.Life_Table_Wht{
    display: block;
    margin: auto;
}

.AnimatedText_1{
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

.fade-text {
    display: block;
    color: transparent;
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 40px;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 300% 300%;
  
    &.to__right {
      background-image: linear-gradient(
        to right,
        #fff 30%,
        rgba(255, 255, 255, 0) 60%
      );
      background-position: left 100% center;
      transform: translate(-20px, 0);
      animation: toRight var(--duration, 2.4s) var(--ease) var(--delay, 0.6s) forwards;
    }
  
    &.to__left {
      background-image: linear-gradient(
        to left,
        #fff 30%,
        rgba(255, 255, 255, 0) 60%
      );
      background-position: right 100% center;
      transform: translate(20px, 0);
      animation: toLeft var(--duration, 2.4s) var(--ease) var(--delay, 0.6s) forwards;
    }
  
    &.to__top {
      background-image: linear-gradient(
        to top,
        #fff 30%,
        rgba(255, 255, 255, 0) 50%
      );
      background-position: center 0;
      transform: translate(0, 20px);
      animation: toTop var(--duration, 2.4s) var(--ease) var(--delay, 0.6s) forwards;
    }
  
    &.to__bottom {
      background-image: linear-gradient(
        to bottom,
        #fff 30%,
        rgba(255, 255, 255, 0) 50%
      );
      background-position: center 100%;
      transform: translate(0, -20px);
      animation: toBottom var(--duration, 2.4s) var(--ease) var(--delay, 0.6s) forwards;
    }
  }
  
  @keyframes toRight {
    100% {
      transform: translate(0, 0);
      background-position: left 0% center;
    }
  }
  @keyframes toLeft {
    100% {
      transform: translate(0, 0);
      background-position: right 0% center;
    }
  }
  @keyframes toBottom {
    100% {
      transform: translate(0, 0);
      background-position: center 0%;
    }
  }
  @keyframes toTop {
    100% {
      transform: translate(0, 0);
      background-position: center 100%;
    }
  }

.slide-in {
  overflow: hidden;
    display: inline-block;
}

.slide-in_inner {
  display: inline-block;

}

/*左右のアニメーション*/
.leftAnime{
    opacity: 0;/*事前に透過0にして消しておく*/
}

.slideAnimeLeftRight {
  animation-name:slideTextX100;
  animation-duration:0.8s;
  animation-fill-mode:forwards;
    opacity: 0;
}

@keyframes slideTextX100 {
  from {
  transform: translateX(-100%); /*要素を左の枠外に移動*/
        opacity: 0;
  }

  to {
  transform: translateX(0);/*要素を元の位置に移動*/
    opacity: 1;
  }
}

.slideAnimeRightLeft {
  animation-name:slideTextX-100;
  animation-duration:0.8s;
  animation-fill-mode:forwards;
    opacity: 0;
}
  
  
@keyframes slideTextX-100 {
  from {
  transform: translateX(100%);/*要素を右の枠外に移動*/
    opacity: 0;
  }

  to {
  transform: translateX(0);/*要素を元の位置に移動*/
    opacity: 1;
  }
}